import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const MomsBelief = () => {
  const headingbanner = {
    title: `Mom’s Belief`,
    content: `An E-Learning Platform`,
  };

  const data = {
    images: ["moms-belief.webp", "moms-belief-2.webp"],
    firstAlt: "Landing Page Of E-learning Platform",
    firstTitle: "Landing Page", 
    secondAlt: "Complete Details Of Programs",
    secondTitle: "Programs Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: [
          "UX Consultation",
          "UI UX Designing",
          "Usability Testing",
        ],
      },
      {
        title: "Platform",
        text: ["Web Portal", "Mobile Responsive"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing website that offered holistic care for children lacked a comprehensive platform that integrates various aspects of child development, hindering parents and caregivers in accessing reliable resources and services to ensure the overall well-being of children.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `In order to address the challenges faced by parents and caregivers in accessing comprehensive holistic care for children, we came up with a solution to develop a user-centric website that serves as a one stop platform by integrating resources and personalising guidance.`,
        ],
      },
    ],
    image: ["moms-belief-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Delve Into Our Background`,
    para: [
      `Mom's Belief is an innovation and research driven holistic care provider for children with special needs.`,
      `It operates through home-based program as well as with an extensive list of Therapy Centres & Inclusive Schools across the globe.`
    ],
    content: {
      image: ["moms-belief-projectone.webp"],
      imageAlt: 'Home Page to get an overview of the platform ',
      imageTitle: 'Home Page',
    },
  };

  const projectData2 = {
    title: `Explore Our Offerings`,
    para: [
      `A diverse range of programs are designed to cater for the unique needs and interests of children at different stages of their development.`,
    ],
    content: {
      image: ["moms-belief-projecttwo.webp"],
      imageAlt: 'Select Programs based on certain categories',
      imageTitle: 'Programs',
    },
  };

  const projectData3 = {
    title: `Empowering Parents & Children at every step of their way`,
    para: [
      `We designed the platform in such a way that it becomes easier for parents to navigate across various aspects of child development programs and easily access the educational resources.`,
    ],
    content: {
      image: [
        // "moms-belief-projectthree-first.webp",
        "moms-belief-projectthree-2.webp",
        "moms-belief-projectthree-3.webp",
        "moms-belief-projectthree-4.webp",
      ],
      firstAlt: 'UI Design to show complete program details',
      firstTitle: 'Program Details',
      secondAlt: 'Programs List and its details',
      secondTitle: 'Program List',
      thirdAlt: 'Programs List and its details',
      thirdTitle: 'Program List',
    },
  };

  const conclusionData = [
    {
      para: [
        `We are dedicated to providing a comprehensive platform that supports the holistic development of children and empowers parents every step of the way. `,
        `Our program offerings go beyond just benefiting children; they aim to enhance the parenting experience by offering expert guidance, fostering parent-child bonding, building a supportive community, promoting personal growth and self-care, and providing accessible resources and tools.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={11} />
      </div>
    </Layout>
  );
};

export default MomsBelief;

export const Head = () => (
  <Seo title="Mom's Belief - Octet Design Studio" description="We offered UX consultation, UI UX designing and Usability Testing Services for a platform dedicated to growth and developmental needs of special children." />
)